/* You can add global styles to this file, and also import other style files */
/*
@use '@angular/material' as mat;
//@import "~bootstrap/scss/bootstrap";

@import "~@angular/material/prebuilt-themes/indigo-pink.css";


// Import library functions for theme creation.

// Include non-theme styles for core.
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.core();

// Define the application's custom theme
$primary: mat.define-palette(mat.$indigo-palette);
$accent:  mat.define-palette(mat.$pink-palette, A200, A100, A400);
//$theme: mat.define-light-theme($primary, $accent);

$theme: mat-light-theme((color: (primary: $primary,
        accent: $accent,
      ),
      typography: mat.define-typography-config(),
      density: 0,
    ));

// Regular (light theme)
// Include theme styles for Angular Material components
// and include theme styles for your custom components.
@include mat.all-component-themes($theme);

// Define the application's dark theme
$primary-dark: mat.define-palette(mat.$teal-palette);
$accent-dark:  mat.define-palette(mat.$teal-palette, A200, A100, A400);

// Use mat-dark-theme instead of mat-light-theme
$dark-theme: mat.define-light-theme($primary-dark, $accent-dark);

.dark {
    @include mat.all-component-themes($dark-theme);
}
*/

@use '@angular/material' as mat;

@include mat.core();

// Define a dark theme
$dark-theme: mat.define-dark-theme((color: (primary: mat.define-palette(mat.$pink-palette),
        accent: mat.define-palette(mat.$blue-grey-palette),
      ),
      // Only include `typography` and `density` in the default dark theme.
      typography: mat.define-typography-config(),
      density: 0,
    ));

// Define a light theme
$light-theme: mat.define-light-theme((color: (primary: mat.define-palette(mat.$indigo-palette),
        accent: mat.define-palette(mat.$pink-palette),
      ),
    ));

// Apply the dark theme by default
@include mat.core-theme($dark-theme);
@include mat.button-theme($dark-theme);

// Apply the light theme only when the user prefers light themes.
@media (prefers-color-scheme: light) {
  // Use the `-color` mixins to only apply color styles without reapplying the same
  // typography and density styles.
  @include mat.core-color($light-theme);
  @include mat.button-color($light-theme);
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


:root {
  --main-color: #ecf0f1;
  --point-color: #555;
  --size: 5px;
}

.loader {
  background-color: var(--main-color);
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0; left: 0;
  display: flex;
  align-items: center;
  align-content: center; 
  justify-content: center;  
  z-index: 100000;
}

.loader__element {
  border-radius: 100%;
  border: var(--size) solid var(--point-color);
  margin: calc(var(--size)*2);
}

.loader__element:nth-child(1) {
  animation: preloader .6s ease-in-out alternate infinite;
}
.loader__element:nth-child(2) {
  animation: preloader .6s ease-in-out alternate .2s infinite;
}

.loader__element:nth-child(3) {
  animation: preloader .6s ease-in-out alternate .4s infinite;
}

@keyframes preloader {
  100% { transform: scale(2); }
}